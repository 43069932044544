import React, { useState } from "react"
import PropTypes from "prop-types"
import ReactMarkdown from "react-markdown"
import Layout from "../components/Layout/Layout"
import GlobalContext from "../context/GlobalContext"
import { setDataLayer, trackPageView } from "../helpers/dataLayerSetter"
import { get } from "../helpers/utils"
import ImageWithText from "../components/ImageWithText/ImageWithText"
import ProductInfo from "../components/ProductInfo/ProductInfo"
import ProductNutrition from "../components/ProductNutrition/ProductNutrition"

import WhereToBuyIcon from "../assets/icons/icon-where-to-buy.inline.svg"
import TabSwitcher from "../components/UI/TabSwitcher/TabSwitcher"
import Typography from "../components/UI/Typography/Typography"
import BrandTitle from "../components/BrandTitle/BrandTitle"
import ImageCardsLayout from "../components/ImageCardsLayout/ImageCardsLayout"
import { BISCUIT_SUMMARY } from "../components/ImageWithText/ImageWithText-tw-styles"
import SummaryTop from "../components/ProductDetail/SummaryTop"
import SummaryBottom from "../components/ProductDetail/SummaryBottom"
import { gTagScriptConsent } from "../components/Scripts/Gtm.Head"

const ProductDetail = ({ path, pageContext }) => {
    const { globalComponents, seoData, dataLayer, globalLabels, product, productApiData } = pageContext

    setDataLayer(dataLayer, seoData, "Product Detail Page")
    trackPageView(path)

    const productLabelsRaw = get(product.labels, "labels.internal.content")
    const productLabels = productLabelsRaw ? JSON.parse(productLabelsRaw) : {}

    const [currentTab, setCurrentTab] = useState(0)

    return (
        <GlobalContext labels={globalLabels}>
            <Layout
                seoData={seoData}
                globalComponents={globalComponents}
                className={`bg-warmWhite product-color-${product.productMeta?.color ?? "ORANGE"}`}
            >
                <div className="overflow-x-hidden">
                    <section className="pdp-video-container">
                        <div className="container">
                            {productApiData.ProductImage && (
                                <picture className="pdp-video-video">
                                    <img src={productApiData.ProductImage[4]?.url} alt={product?.productMeta?.productImageAlt ?? productApiData.ProductName} />
                                </picture>
                            )}

                            {productApiData.ProductImage && (
                                <picture className="pdp-video-picture">
                                    <source
                                        srcSet={productApiData.ProductImage[0]?.url}
                                        type="image/png"
                                        media="(min-width: 992px)"
                                    />
                                    <img role="presentation" src={productApiData.ProductImage[3]?.url} alt="" />
                                </picture>
                            )}

                            <div className="pdp-video-content">
                                <div className="top-left text-center">
                                    <span className={"category-text"}>{productApiData?.CategoryName}</span>
                                    <h1 className={`product-text text-[28px] font-bold my-3 normal-case`}>
                                        {productApiData?.ProductName}
                                        <span className={`product-text text-[16px] block font-bold normal-case`}>
                                            {productApiData?.ProductVariant}
                                        </span>
                                    </h1>
                                </div>
                                <div className="bottom-right justify-self-end lg:mr-[-100px]">
                                    <BrandTitle
                                        className={"justify-center lg:justify-start lg:mt-[30px]"}
                                        {...product.brandHeroTitle}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>

                    {product.biscuitSummary?.length > 0 && (
                        <section
                            className={
                                "biscuit-summary mt-[-180px] md:mt-[-320px] lg:mt-[-360px] xl:mt-[-440px] xxxl:mt-[-500px]"
                            }
                        >
                            <div className="relative">
                                <div className="biscuit-summary-glow" />
                                <SummaryTop />
                            </div>
                            <div
                                className={
                                    "biscuit-summary-wrapper bg-[var(--product-color)] mt-[-1px] mb-[-1px] pb-[60px] lg:pb-0"
                                }
                            >
                                <div className={"container text-center pb-[130px] lg:pb-[100px] xl:pb-[50px]"}>
                                    <BrandTitle
                                        {...product.biscuitSummaryTitle}
                                        className={"mx-auto justify-center text-left !py-0 !pb-8"}
                                    />
                                    <div className={"row gap-y-[60px]"}>
                                        {product.biscuitSummary.map((summary, index) => (
                                            <div className={"col-12 lg:col-4"} key={index}>
                                                <ImageWithText {...summary} style={BISCUIT_SUMMARY} />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <SummaryBottom />
                        </section>
                    )}
                    <section>
                        <div
                            className={
                                "container text-center relative -mt-[140px] xs:-mt-[165px] xm:-mt-[175px] md:-mt-[285px] lg:-mt-[310px] xl:-mt-[340px] xxxl:-mt-[390px]"
                            }
                        >
                            <Typography
                                content={productLabels.needToKnow.title}
                                className={"font-anguita text-[34px] leading-none [&_h2]:text-warmWhite"}
                                allow_br_tag
                            />
                            {/* TMP? wrapper might not be needed */}
                            <div className={""}>
                                <TabSwitcher
                                    currentTab={currentTab}
                                    onTabChange={setCurrentTab}
                                    tabs={[productLabels.needToKnow.productInfo, productLabels.needToKnow.nutrition]}
                                    className={"mx-auto w-fit mt-[20px] mb-[65px]"}
                                />
                                <span className={"category-text"}>{productApiData?.CategoryName}</span>
                                <h2 className={"product-text text-[28px] font-bold my-3 normal-case"}>
                                    {productApiData?.ProductName}
                                    <span className={"text-[16px] block"}>{productApiData?.ProductVariant}</span>
                                </h2>
                                {productApiData?.ProductLongDescription && (
                                    <ReactMarkdown className={"max-w-md my-2 text-center mx-auto mb-[60px]"}>
                                        {productApiData?.ProductLongDescription}
                                    </ReactMarkdown>
                                )}

                                {currentTab === 0 && (
                                    <ProductInfo
                                        data={productApiData}
                                        productInfoDecor={product?.productInfoDecor}
                                        servingIcon={product?.servingIcon}
                                        labels={productLabels}
                                        productPackshotImageAlt={product?.productMeta?.productPackshotImageAlt}
                                    />
                                )}
                                {currentTab === 1 && <ProductNutrition data={productApiData} labels={productLabels} />}
                            </div>
                        </div>
                    </section>
                    {product.whereToBuy?.cards?.length > 0 && (
                        <section className={"text-center mt-[100px]"}>
                            <WhereToBuyIcon className={"mx-auto"} />
                            <Typography
                                className={"font-anguita text-[30px] md:text-[34px]"}
                                content={productLabels.whereToBuy.title}
                            />
                            <p>{productLabels.whereToBuy.subtitle}</p>

                            <ImageCardsLayout
                                cardItems={product.whereToBuy.cards}
                                variant={product.whereToBuy.variant}
                                isSlider={product.whereToBuy.isSlider}
                            />
                        </section>
                    )}

                    {product.relatedProducts && (
                        <section className="mt-[42px] pb-[80px]">
                            <ImageCardsLayout
                                cardItems={product?.relatedProducts?.cards}
                                {...product.relatedProducts}
                            />
                        </section>
                    )}
                </div>
            </Layout>
        </GlobalContext>
    )
}
export const Head = () => {
    const dataObject = typeof window !== "undefined" ? window?.gtmDataLayer : {}
    return (
        <>
            <script
                id="first-unique-id"
                type="text/javascript"
                dangerouslySetInnerHTML={{
                    __html: `var MdlzdataLayer = ${JSON.stringify(dataObject)};`,
                }}
            />
            <script
                async
                type="text/javascript"
                dangerouslySetInnerHTML={{ __html: gTagScriptConsent }}
            />
        </>
    )
}

ProductDetail.propTypes = {
    path: PropTypes.string,
    pageContext: PropTypes.shape({
        globalComponents: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        seoData: PropTypes.object,
        dataLayer: PropTypes.object,
        globalLabels: PropTypes.object,
        product: PropTypes.shape({
            biscuitSummaryTitle: PropTypes.object,
            biscuitSummary: PropTypes.array,
            whereToBuy: PropTypes.object,
            servingIcon: PropTypes.object,
            productMeta: PropTypes.shape({
                color: PropTypes.string,
                detailIngredients: PropTypes.object,
                cardIngredients: PropTypes.object,
                productImageAlt: PropTypes.string,
                productPackshotImageAlt: PropTypes.string,
            }),
            brandHeroTitle: PropTypes.object,
            slug: PropTypes.string,
            labels: PropTypes.object,
            relatedProducts: PropTypes.object,
            productInfoDecor: PropTypes.object,
        }),
        productApiData: PropTypes.shape({
            CategoryName: PropTypes.string,
            NutritionalTable: PropTypes.array,
            ProductImage: PropTypes.array,
            ProductIngredients: PropTypes.string,
            ProductLongDescription: PropTypes.string,
            ProductName: PropTypes.string,
            ProductVariant: PropTypes.string,
            ServingPerUnit: PropTypes.string,
            servingSize: PropTypes.string,
            servingWeight: PropTypes.string,
            Weight: PropTypes.string,
        }),
    }),
}

export default ProductDetail
